import NRLogo from "../assets/images/NRLogo.png";
import BILogo from "../assets/images/BILogo.png";
import DHLogo from "../assets/images/DHLogo.jpg";
import CryptopediaLogo from "../assets/images/CryptopediaLogo.png";
import Trivia from "../assets/images/Trivia.png";
import Tesla from "../assets/images/Tesla.png";
import BezosBot from "../assets/images/BezosBot.png";
import MM from "../assets/images/MindfulMoments.png";

const Cards = [
	{
		id: 0,
		title: "Biz Incubate",
		subTitle: "Business Incubator Bridging the Generational Gap",
		imgSrc: BILogo,
		link: "https://bizincubate.com/",
	},
	{
		id: 1,
		title: "Dailee Holdings LLC.",
		subTitle: "Compliant Solutions for the Cannabinoid Industry",
		imgSrc: DHLogo,
		link: "https://daileecbd.com/",
	},
	{
		id: 2,
		title: "Tech Trivia",
		subTitle: "Computer & Coding Trivia Game",
		imgSrc: Trivia,
		link: "https://tech-trivia-rck.netlify.app/",
	},
	// {
	//     id: 1,
	//     title: 'New Remedies',
	//     subTitle: 'First & Only Federally Compliant CBD Franchise',
	//     imgSrc: NRLogo,
	//     link: 'https://newremediescbd.com/',
	// },
	{
		id: 3,
		title: "Cryptopedia",
		subTitle: "Cryptocurrency Trading Simulator",
		imgSrc: CryptopediaLogo,
		link: "https://cryptopedia.netlify.app/",
	},
	{
		id: 4,
		title: "CEO Takeover",
		subTitle: "Futuristic Shooter Game",
		imgSrc: BezosBot,
		link: "https://ceo-takeover.netlify.app/",
	},
	{
		id: 5,
		title: "Nikola Tesla Electrical Storm Tracker",
		subTitle: "Track Storms Using Google Maps API",
		imgSrc: Tesla,
		link: "https://Nikola-Tesla-electric.netlify.app/",
	},
	{
		id: 6,
		title: "Mindful Moments",
		subTitle: "Meditation Web App",
		imgSrc: MM,
		link: "https://mindful-moments.netlify.app/",
	},
];

export default Cards;
